<template>
    <v-form v-model="valid">
        <v-row>
            <v-col>
                <v-autocomplete
                    :items="$store.state.SIETT.tramites"
                    v-model="Tipo_de_tramite"
                    item-text="name"
                    return-object
                    required
                    label="Tipo de tramite"
                    filled
                    rounded 
                    dense
                    
                >

                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                 <v-text-field filled rounded dense label="Fecha" v-model="Fecha">

                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field filled rounded dense label="Documento del propietario" v-model="Documento_del_propietario">

                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field filled rounded dense label="Observaciones" v-model="Observaciones">

                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-text-field filled rounded dense label="Parque automotor" v-model="Parque_automotor">

                </v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
export default {
    props: {
        Documento_del_propietario: {
            type: String,
            required: true
        },
        Fecha: {
            type: String,
            required: true
        },
        Tipo_de_tramite: {
            type: String,
            required: true
        },
        Observaciones: {
            type: String,
            required: true
        },
        Parque_automotor: {
            type: String,
            required: true
        },
    },
    data(){
        return {
            
            valid: true,
        }
    },
    methods: {
        
    }
}
</script>