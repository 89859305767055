<template>
    <v-sheet>
        <v-row>
                <v-col>
                     <v-select
                        prepend-icon="mdi-file-document"
                        @change="seleccionTipoDocumental"
                        :items="tipos_documentales"
                        label="Selecciona el tipo documental al que pertenecen los archivos seleccionados"
                        v-model="selectDocumento"
                        rounded
                        filled
                        dense
                        return-object
                        item-text="nombre"
                        />
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                     <ArchivosSeleccionados/>
                </v-col>
                <v-col cols="12">
                     <InputsTipoDocumental/>
                </v-col>
            </v-row>
            <v-row>
                <v-btn class="mx-3" dark block color="indigo darken-4" @click="digitalizar">Digitalizar <v-icon>mdi-upload</v-icon> </v-btn>
            </v-row>
    </v-sheet>
</template>
<script>
import InputsTipoDocumental from './formularios/InputsTipoDocumental'
import ArchivosSeleccionados from './ArchivosSeleccionados'
const axios = require("axios").default;
export default {
    components: {
        InputsTipoDocumental,
        ArchivosSeleccionados
    },
    data(){
        return {
            selectDocumento: null,
            tipos_documentales: [],
        }
    },
    created(){
        this.consultar_tipos_documentales()
    },
    methods: {
      async concatenarDatos() {
            let obj = "";
            for (
                let index = 0;
                index <
                this.$store.state.formularios.inputs_tipo_documental.campos.length;
                index++
            ) {
                obj = obj + `"${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}":"${
                                this.$store.state.formularios.inputs_tipo_documental.campos[index].value
                            }",`;
            }
            let objeto = obj.substring(0, obj.length - 1)
            //obj = obj +`"fecha_limite":"${this.$store.state.formularios.inputs_tipo_documental.fecha_limite}"`;
            console.log(objeto);
            obj = "{" + objeto + "}";

            return obj;
        },
       async digitalizar(){
           try {
               console.log(this.selectDocumento)
               let obj = await this.concatenarDatos();
            
                console.log(obj);
                obj = JSON.parse(obj);
                console.log(obj);
                obj.fecha_creacion = Date.parse( new Date ()) 
                let datos_archivos = []
                for (let index = 0; index <  this.$store.state.archivos_seleccionados.length; index++) {
                   datos_archivos.push(JSON.stringify(this.$store.state.archivos_seleccionados[index]))
                    
                }
                obj.datos_archivos = datos_archivos

                obj.nombre_documento = "Nombre prueba";

                console.log(obj);

                //Enviamso el dato al endpoint asociado del tipo documental seleccionado
                var response = await axios.post(
                "https://siett.educandote.co/types/collections/" +
                    this.selectDocumento.nombre_endpoint_almacenamiento +
                    "/documents",
                obj,
                {
                    headers: {
                    "Content-Type": "application/json",
                    "X-TYPESENSE-API-KEY":
                        "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
                    },
                }
                );
                console.log(response)
                this.$store.commit('exitoso','Los archivos han sido digitalizados exitosamente')
                this.$emit('regresar')
           } catch (error) {
               this.$store.commit('error','Algo ha salido mal digitalizando los archivos '+error.message)
               console.log(error)
           }
       },
       async consultar_tipos_documentales(){
          let response = await this.$store.dispatch('llamado_get',
            {url: 'https://siett.educandote.co/parse/classes/prueba01Sector/'+this.$store.dataUser.dataUser.sector,tipo_header:'parse'})

            console.log(response)
            this.tipos_documentales = [...response.data.tipos_documentales]

        },
        seleccionTipoDocumental() {
      //'Orden de pago','Impuesto predial','Impuesto ICA','Resoluciones','Historias laborales'
            this.$store.state.formularios.inputs_tipo_documental = {
                campos: [],
            };
            console.log(this.selectDocumento);
            this.$store.state.formularios.inputs_tipo_documental.campos = [...this.selectDocumento.parametros]
        },
    }
}
</script>